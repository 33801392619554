export default [
  {
    title: 'Dashboard',
    route: 'home',
    resource: 'dashboard',
    action: 'read',
    icon: 'BarChart2Icon',
  },
  {
    title: 'Histórico',
    route: 'history',
    resource: 'history',
    action: 'read',
    icon: 'ClipboardIcon',
  },
  {
    title: 'Parâmetros',
    // icon: 'UsersIcon',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Aulas',
        route: 'courses',
        resource: 'admin',
        action: 'read',
      },
      {
        title: 'Modalidades',
        route: 'modalidades',
        resource: 'admin',
        action: 'read',
      },
    ],
  },
  {
    title: 'Usuários',
    icon: 'UsersIcon',
    children: [
      {
        title: 'Clientes',
        route: 'clients-list',
        resource: 'client',
        action: 'read',
      },
      {
        title: 'Professores',
        route: 'professors-list',
        resource: 'professor',
        action: 'read',
      },
      {
        title: 'Alunos',
        route: 'students-list',
        resource: 'student',
        action: 'read',
      },
      {
        title: 'Administradores',
        route: 'admins-list',
        resource: 'admin',
        action: 'read',
      },
    ],
  },
  // {
  //   title: 'FAQ',
  //   route: 'faq',
  //   icon: 'HelpCircleIcon',
  //   resource: 'admin',
  //   action: 'read',
  // },
]
